var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ResourceSelect" },
    [
      _c("van-search", {
        staticStyle: { height: "70px" },
        attrs: {
          shape: "round",
          background: "#e54e17",
          placeholder: "请输入搜索关键词",
        },
        on: { search: _vm.onSearch },
        model: {
          value: _vm.searchKey,
          callback: function ($$v) {
            _vm.searchKey = $$v
          },
          expression: "searchKey",
        },
      }),
      _c("div", { staticClass: "Search-Term Area-Content" }, [
        _c(
          "div",
          { staticClass: "Resource-Term" },
          _vm._l(_vm.navBar, function (nav, navIndex) {
            return _c(
              "span",
              {
                key: navIndex,
                class:
                  _vm.checkNavMap.name === nav.name
                    ? "Span-Default Span-Check"
                    : "Span-Default",
                on: {
                  click: function ($event) {
                    return _vm.checkNav(nav)
                  },
                },
              },
              [_vm._v(" " + _vm._s(nav.name) + " ")]
            )
          }),
          0
        ),
      ]),
      _c(
        "div",
        { staticClass: "Resource-Content Area-Content" },
        [
          _c(
            "van-checkbox-group",
            {
              on: { change: _vm.checkResourceChange },
              model: {
                value: _vm.checkResourceList,
                callback: function ($$v) {
                  _vm.checkResourceList = $$v
                },
                expression: "checkResourceList",
              },
            },
            [
              _c(
                "van-list",
                {
                  attrs: {
                    offset: 100,
                    finished: _vm.finished,
                    "finished-text": "没有更多了",
                    "immediate-check": false,
                  },
                  on: { load: _vm.onLoad },
                  model: {
                    value: _vm.loading,
                    callback: function ($$v) {
                      _vm.loading = $$v
                    },
                    expression: "loading",
                  },
                },
                _vm._l(_vm.resourceData, function (resource, resourceIndex) {
                  return _c(
                    "van-checkbox",
                    {
                      key: resourceIndex,
                      attrs: {
                        "checked-color": "#e54e17",
                        shape: "square",
                        name: resource,
                      },
                    },
                    [
                      [
                        _c("div", { staticClass: "Resource-Single" }, [
                          _c("div", { staticClass: "Resource-Single-One" }, [
                            _c("span", [_vm._v(_vm._s(resource.nameCn))]),
                            _c("span", [
                              _vm._v("(" + _vm._s(resource.nameEn) + ")"),
                            ]),
                            _c("span", [_vm._v("*****")]),
                          ]),
                          _c("div", { staticClass: "Resource-Single-Two" }, [
                            _c("span", [
                              _vm._v(_vm._s(resource.countryNameCn) + "/"),
                            ]),
                            _c("span", [
                              _vm._v(_vm._s(resource.cityNameCn) + "/"),
                            ]),
                            _c("span", [_vm._v(_vm._s(resource.star) + "星/")]),
                            _c("span", [
                              _vm._v("评分" + _vm._s(resource.score) + "/"),
                            ]),
                            _c("span", [_vm._v("房间数100/")]),
                            _c("span", [_vm._v("住宿产品6")]),
                          ]),
                        ]),
                      ],
                    ],
                    2
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "van-button",
        {
          staticClass: "Next-Step",
          attrs: { color: "#e54e17", type: "default", block: "" },
          on: {
            click: function ($event) {
              return _vm.$router.go(-1)
            },
          },
        },
        [_vm._v("确认")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
	<div class="ResourceSelect">
		<van-search
			v-model="searchKey"
			style="height: 70px"
			shape="round"
			@search="onSearch"
			background="#e54e17"
			placeholder="请输入搜索关键词"
		/>
		<div class="Search-Term Area-Content">
			<div class="Resource-Term">
				<span 
				  :class="checkNavMap.name === nav.name ? 'Span-Default Span-Check' : 'Span-Default'" 
					v-for="(nav,navIndex) in navBar" 
					@click="checkNav(nav)" 
					:key="navIndex">
					  {{nav.name}}
				</span>
			</div>
		</div>

		<div class="Resource-Content Area-Content">
			<van-checkbox-group v-model="checkResourceList" @change="checkResourceChange">
					<van-list
					  :offset="100"
						v-model="loading"
						:finished="finished"
						finished-text="没有更多了"
						:immediate-check="false"
						@load="onLoad"
					>
						<van-checkbox v-for="(resource,resourceIndex) in resourceData" :key="resourceIndex" checked-color="#e54e17" shape="square" :name="resource">
							<template>
								<div class="Resource-Single">
									<div class="Resource-Single-One">
										<span>{{resource.nameCn}}</span>
										<span>({{resource.nameEn}})</span>
										<span>*****</span>
									</div>

									<div class="Resource-Single-Two">
										<span>{{resource.countryNameCn}}/</span>
										<span>{{resource.cityNameCn}}/</span>
										<span>{{resource.star}}星/</span>
										<span>评分{{resource.score}}/</span>
										<span>房间数100/</span>
										<span>住宿产品6</span>
									</div>
								</div>
							</template>
						</van-checkbox>
					</van-list>
			</van-checkbox-group>
		</div>
		<van-button class="Next-Step" color="#e54e17" @click="$router.go(-1)" type="default" block>确认</van-button>
	</div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
import { navTabData } from '@/utils/unit'
import ResourceApi from '@/components/Api/resource'
import { mapMutations } from 'vuex'
export default {
	name:  'ResourceSelect',
	components: {
		
	},
	// 定义属性
	data() {
		return {
			show: false,
			total: 0,
			searchKey: '',
			navBar: navTabData,
			checkNavMap: {
				name: '住',
				code: 0
			},
			checkResourceList: [],
			resourceData: [],
			loading: false,
      finished: false,
			page: 1,
			parentIndex: -1,
			state: 0
		}
	},
	// 计算属性，会监听依赖属性值随之变化
	computed: {},
	// 监控data中的数据变化
	watch: {},
	// 方法集合
	methods: {
		...mapMutations('supplier',['UPDATE_SET_RESOURCEAGENTS']),
		//数据初始化
		init() {
			this.parentIndex = this.$route.query.index
			this.state = this.$route.query.state
			this.checkNavMap = {name: this.navBar.find(x => x.code == this.state).name,code: this.state}
			this._getData()
		},
		checkNav(nav) {
			this.checkNavMap = nav
			this.page = 1
			this.resourceData = []
			this._getData()
		},
		onSearch(e) {},
		_getData () {
			let _obj = {
				page: this.page,
				size: 30,
				q: {
					state: this.checkNavMap.code
				}
			}
			new ResourceApi('resource').resourceList(_obj).then((result) => {
				if (result.status === 200) {
					this.resourceData.push(...result.data.records)
					this.total = result.data.total
				  this.loading = false
				}
			})
		},
		
		onLoad() {
			console.log("总条数",this.resourceData.length,this.total)
			if (this.total > this.resourceData.length) {
        this.page++
				this._getData()
			} else {
			  this.loading = false
			}
    },

		checkResourceChange(e) {
			let _obj = {
				checkReourceList: e,
				index: this.parentIndex
			}
			this.UPDATE_SET_RESOURCEAGENTS(_obj)
		}
	},
	// 生命周期 - 创建完成（可以访问当前this实例）
	created() {},
	// 生命周期 - 挂载完成（可以访问DOM元素）
	mounted() {
		this.init()
	},
	beforeCreate() {}, // 生命周期 - 创建之前
	beforeMount() {}, // 生命周期 - 挂载之前
	beforeUpdate() {}, // 生命周期 - 更新之前
	updated() {}, // 生命周期 - 更新之后
	beforeDestroy() {}, // 生命周期 - 销毁之前
	destroyed() {}, // 生命周期 - 销毁完成
	activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>

<style scoped lang="scss">
  .Area-Content {
    padding: 0.2rem;
	}
	.Search-Term {
		height: 40px;
		background-color: white;
		.Resource-Term {
			.Span-Default {
				border: 1px solid #B3B3B3;
				border-radius: 50%;
				text-align: center;
				width: 0.5rem;
				height: 25px;
				display: inline-flex;
				align-items: center;
				justify-content: center;
				margin-right: 10px;
			}
			.Span-Check {
       border: none;
			 background-color: #e54e17;
			 color: white;
			}
		}
	}

  .Next-Step {
		height: 37px;
	}
	.Resource-Content {
		overflow-y: scroll;
    height: calc(100vh - 147px) !important;
    overflow: auto !important;
		.van-checkbox-group {
			.van-checkbox {
				display: flex;
				align-items: flex-start;
				width: 100%;
				min-height: 50px;
				font-size: 12px;
				border-radius: 0.1rem;
				background-color: rgba(255, 255, 255, 1);
				border: 1px solid rgba(242, 237, 237, 1);
				margin-top:-1px;
				margin-bottom: 10px;
				/deep/ .van-checkbox__icon {
					.van-icon {
						border-color: #f2eded;
					}
				}
				.Resource-Single {
					display: flex;
					flex-direction: column;
					justify-content: center;
					padding-top: 10px;
					padding-bottom: 10px;
				}
				.Resource-Single-One {
					display: flex;
          flex-wrap: wrap;
					font-weight: 700;
					font-size: 14px;
				}
				.Resource-Single-Two {
					color: rgba(69, 101, 117, 1);
					font-size: 12px;
					font-family: PingFangSC-regular;
				}
			}
		}
	}
</style>